<template>
  <div>
    <!-- 吹扫试验记录查看 -->
    <div class="content1">
      <div class="all">
        <div ref="SearchBar" class="SearchBar" style="padding-left: 20px">
          工程编号/名称:
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.searchText"
          ></el-input>
          <span style="margin-right: 20px"></span>
          安装施工队:
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.install"
          ></el-input>
          <span style="margin-right: 20px"></span>
          现场管理员:
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.scene"
          ></el-input>
          <span style="margin-right: 20px"></span>
          试验结果:
          <el-select
            v-model="searchBox.result"
            clearable
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div style="margin-top: 10px">
            提交时间:
            <el-date-picker
              size="small"
              v-model="time"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
              @change="timeChange"
            >
            </el-date-picker>
            <span style="margin-right: 20px"></span>
            <el-button
              type="primary"
              class="SearchBtn"
              size="small"
              @click="search"
              >查询</el-button
            >
            <el-button
              type="primary"
              class="SearchBtn"
              size="small"
              @click="resetData"
              >重置</el-button
            >
          </div>
        </div>
        <div class="table">
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            border
            height="calc(100vh - 390px)"
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="projectNumber"
              label="工程编号"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="siteManager"
              label="现场管理员"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="realname"
              label="安装施工队"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="purge"
              label="试验状态"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                  {{ row.state == 2 ? "实验中" : (row.state == 4 ? "实验中止" : '试验完成') }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="首次提交时间"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="purgeDate"
              label="试验结束时间"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="result"
              label="试验结果"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <el-tag :type="row.purge == 1 ? 'success' :  'warning'">
                {{  row.purge == 1 ? "合格" :  '未出结果'}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="left" show-overflow-tooltip>
              <template slot-scope="{ row }">
                <el-button type="primary" size="small" @click="seeDetil(row)"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="searchBox.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="searchBox.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="吹扫详情"
      :visible.sync="detilDialog"
      width="50%"
      :close-on-click-modal="false"
    >
      <div style="max-height: 600px; overflow: auto">
        <el-tabs v-model="indexActiveName">
          <el-tab-pane
            v-for="(item, index) in project"
            :label="item[0].purgeCount + '--' + resulttype[item[0].result]"
            :name="index + ''"
            :key="index"
          >
            <el-table :data="item" style="width: 100%">
              <el-table-column
                type="index"
                label="序号"
                width="80"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="place" label="吹扫位置" align="center">
              </el-table-column>
              <el-table-column label="吹扫视频" align="center">
                <template slot-scope="{ row }">
                  <span @click="seevideo(row)" style="color: #1fa5f9"
                    >查看视频</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="place" label="吹扫结果" align="center">
                <template slot-scope="{ row }">
                  {{ resulttype[row.result] }}
                </template>
              </el-table-column>
              <el-table-column prop="createBy" label="提交人" align="center">
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="提交时间"
                align="center"
                width="150"
              >
              </el-table-column>
              <el-table-column label="整改单" align="center">
                <template slot-scope="{ row }">
                  <el-button
                    v-if="row.result != 1"
                    size="small"
                    @click="seedetilBox(row, 1)"
                    >查看</el-button
                  >
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column label="现场管理处理" align="center">
                <template slot-scope="{ row }">
                  <el-button
                    v-if="row.result != 1"
                    size="small"
                    @click="seedetilBox(row, 2)"
                    >查看</el-button
                  >
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column label="整改后情况" align="center">
                <template slot-scope="{ row }">
                  <el-button
                    v-if="row.result != 1"
                    size="small"
                    @click="seedetilBox(row, 3)"
                    >查看</el-button
                  >
                  <span v-else>-</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="punchDialog"
      width="400px"
      :close-on-click-modal="false"
    >
      <div>
        <div v-for="item in rectification" :key="item.id">
          <div v-if="rectificationType == 1">
            <p class="detil-code">整改单</p>
            <div style="padding: 10px">
              <div class="item-list">整改原因： {{ item.cause }}</div>
              <div class="item-list">
                <div style="margin-bottom: 10px">不合格照片：</div>
                <div>
                  <el-image
                    v-if="item.img"
                    style="width: 100px; height: 100px"
                    :src="item.img.split(',')[0]"
                    :preview-src-list="item.img.split(',')"
                  >
                  </el-image>
                  <span style="margin-left: 10px" v-if="item.img"
                    >共{{ item.img.split(",").length }}张</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="rectificationType == 2">
            <p class="detil-code">整改处理</p>
            <div style="padding: 10px">
              <div class="item-list">处理结果： {{ state[item.state] }}</div>
              <div class="item-list">施工队： {{ item.teams.join("；") }}</div>
              <div class="item-list">操作人： {{ item.updateBy }}</div>
              <div class="item-list">操作时间： {{ item.updateTime }}</div>
            </div>
          </div>
          <div v-if="rectificationType == 3">
            <p class="detil-code">整改情况</p>
            <div style="padding: 10px">
              <div
                v-for="list in item.workInspectionSgdSubsetList"
                :key="list.id"
                style="display: flex; flex-wrap: wrap"
              >
                <div
                  style="margin-right: 20px; margin-bottom: 20px"
                  v-if="list.laterImg"
                >
                  <div class="item-list">
                    整改施工队： {{ list.workTeamName }}
                  </div>
                  <div class="item-list">
                    <div style="margin-bottom: 10px">整改后情况：</div>
                    <div>
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="list.laterImg.split(',')[0]"
                        :preview-src-list="list.laterImg.split(',')"
                      >
                      </el-image>
                      <span style="margin-left: 10px" v-if="list.laterImg"
                        >共{{ list.laterImg.split(",").length }}张</span
                      >
                    </div>
                  </div>
                  <div class="item-list">
                    整改说明： {{ list.explain || "-" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: center;">
          <el-button @click="punchDialog = false">关闭</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="视频"
      :visible.sync="videoDialog"
      width="50%"
      :close-on-click-modal="false"
    >
    <el-tag v-for="(item,index) in videoUrl.split(',')" :key="index" @click="tradeUrl(item)" style="margin-right:10px">视频{{index+1}}</el-tag>
      <video
        :src="videoUrlOpen"
        controls="controls"
        style="width: 100%; height: 500px"
      >
        您的浏览器不支持 video 标签。
      </video>
    </el-dialog>
  </div>
</template>

<script>
import axiosApi from "@/apis/checkOuter/index.js";
import { getIndoorRectify } from "@/RequestPort/checkTask/task";
export default {
  name: "lockout",
  data() {
    return {
      status: "12",
      activeName: 0,
      indexActiveName: "1",
      // 视频
      videoUrl: "",
      condition: true,
      project: {},
      time: [],
      searchBox: {
        current: 1,
        size: 100,
        scene: "",
        result: "",
        searchText: "",
        install: "",
        endDate: "",
        startDate: "",
      },
      currentRow: [],
      total: 0,
      detilDialog: false,
      punchDialog: false,
      videoDialog: false,
      resulttype: {
        1: "合格",
        2: "实验中",
        3: "不合格",
        4: "未进行",
      },
      state: {
        3: "已整改",
        2: "不整改",
        1: "进行整改",
        0: "未整改",
      },
      typeList: [
        {
          label: "合格",
          value: 1,
        },
        {
          label: "不合格",
          value: 3,
        },
        {
          label: "未出结果",
          value: 4,
        },
      ],
      tableData: [{}],
      rectification: null,
      rectificationType: null,
      // 默认播放地址
      videoUrlOpen:''
    };
  },
  mounted() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let t1 = year + "-" + month + "-" + day + " " + "00:00:00";
    let t2 = year + "-" + month + "-" + day + " " + "23:59:59";
    this.time.push(t1);
    this.time.push(t2);
    this.searchBox.startDate = t1;
    this.searchBox.endDate = t2;
    this.loadList(this.searchBox);
  },
  methods: {
    seedetilBox(row, type) {
      getIndoorRectify({ id: row.id, source: "9" }).then((res) => {
        this.rectification = res.data;
        if (type == 2 && !res.data[0].teams) {
          return this.$message("暂无相关信息");
        }
        if (type == 3) {
          let list = res.data[0].workInspectionSgdSubsetList.filter(
            (el) => el.laterImg
          );
          if (!list.length) return this.$message("暂无相关信息");
        }
        this.rectificationType = type;
        this.punchDialog = true;
      });
    },
    seevideo(row) {
      this.videoUrl = row.video;
      this.videoUrlOpen = this.videoUrl.split(',')[0]
      this.videoDialog = true;
    },
    /************************************** 
     * @description		更换视频地址
     * @author			武宏岩
     * @date			2022-09-08
     **************************************/
    tradeUrl(val){
      this.videoUrlOpen = val
    },
    timeChange(val) {
      if (val) {
        this.searchBox.startDate = val[0];
        this.searchBox.endDate = val[1];
      } else {
        this.searchBox.startDate = null;
        this.searchBox.endDate = null;
      }
    },
    handleClick(val, e) {
      console.log(val.start);
      this.activeName = val.name;
      // 调接口判断是否是合格还是不合格
      this.condition = !this.condition;
    },
    seeDetil(row) {
      this.currentRow = row;
      this.project = row.workTestPurges;
      this.detilDialog = true;
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList(this.searchBox);
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList(this.searchBox);
    },
    search() {
      this.searchBox.current = 1;
      this.loadList(this.searchBox);
    },
    async loadList(obj) {
      try {
        let { data } = await axiosApi.getPurge(obj);
        let resultData = {
          1: "合格",
          2: "实验中",
          3: "不合格",
          4: "未出结果",
        };
        let purgeData = {
          4: "实验终止",
          3: "不合格",
          2: "实验中",
          1: "合格",
        };
        // data.records.forEach((el) => {
        //   el.result = el.purge == 1 ? "合格" : "未出结果";
        //   el.purge = el.state == 4 ? "实验中" : el.purge == 1 ? "试验完成" : "实验中";
        // });
        this.tableData = data.records;
        this.total = data.total;
      } catch (error) {}
    },
    resetData() {
      this.searchBox = {
        current: 1,
        size: 100,
        scene: "",
        result: "",
        searchText: "",
        install: "",
        endDate: "",
        startDate: "",
      };
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let t1 = year + "-" + month + "-" + day + " " + "00:00:00";
      let t2 = year + "-" + month + "-" + day + " " + "23:59:59";
      this.time = [t1, t2];
      this.searchBox.startDate = t1;
      this.searchBox.endDate = t2;
      this.loadList(this.searchBox);
    },
  },
};
</script>

<style scoped>
.detil-code {
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn {
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.item-list {
  margin-bottom: 15px;
}
.underline{
  text-decoration: underline;
}
</style>